import gql from "graphql-tag"

export const eventFragment = gql`
  fragment eventFragment on Event {
    id
    slug
    title
    description
    category
    dressCode
    image_publicId: imagePublicId
    startAt
    endAt
    costMin
    costMax
    externalSourceRecordUrl
    publishState

    numberAnalytics {
      numberOfImpressions
      numberOfClicks
    }

    # TODO: many-to-many
    communities {
      id
      slug
      displayName
    }
    place {
      id
      slug
      name

      address_city: addressCity
      address_state: addressState
      address_streetAddress: addressStreetAddress
      address_streetAddress2: addressStreetAddress2
      address_zipCode: addressZipCode
    }

    submitter {
      id
    }

    organizer_email: organizerEmail
    organizer_name: organizerName
    organizer_phone: organizerPhone

    tempPlace_name: tempPlaceName
    tempPlace_url: tempPlaceUrl
    tempPlace_address_city: tempPlaceAddressCity
    tempPlace_address_state: tempPlaceAddressState
    tempPlace_address_streetAddress: tempPlaceAddressStreetAddress
    tempPlace_address_streetAddress2: tempPlaceAddressStreetAddress2
    tempPlace_address_zipCode: tempPlaceAddressZipCode

    isVirtual
    url
  }
`

export const submittedEventFragment = gql`
  fragment submittedEventFragment on Event {
    id
    slug
    title
    description
    category
    dressCode
    image_publicId: imagePublicId
    startAt
    endAt
    costMin
    costMax
    externalSourceRecordUrl
    publishState
    repeatingEventId

    analytics {
      name
      data
      color
    }
    numberAnalytics {
      numberOfImpressions
      numberOfClicks
    }

    place {
      id
      slug
      name

      address_city: addressCity
      address_state: addressState
      address_streetAddress: addressStreetAddress
      address_streetAddress2: addressStreetAddress2
      address_zipCode: addressZipCode
    }

    submitter {
      id
    }

    organizer_email: organizerEmail
    organizer_name: organizerName
    organizer_phone: organizerPhone

    tempPlace_name: tempPlaceName
    tempPlace_url: tempPlaceUrl
    tempPlace_address_city: tempPlaceAddressCity
    tempPlace_address_state: tempPlaceAddressState
    tempPlace_address_streetAddress: tempPlaceAddressStreetAddress
    tempPlace_address_streetAddress2: tempPlaceAddressStreetAddress2
    tempPlace_address_zipCode: tempPlaceAddressZipCode

    isVirtual
    url
  }
`

export const placeFragment = gql`
  fragment placeFragment on Place {
    id
    slug
    name
    shortDescription
    longDescription
    webUrl
    tags
    geometry: geo
    publishState
    category
    subcategory

    mainImage_publicId: mainImagePublicId

    # TODO: many-to-many
    communities {
      id
      slug
      displayName
    }

    contact_email: contactEmail
    contact_name: contactName
    contact_phone: contactPhone

    address_city: addressCity
    address_state: addressState
    address_streetAddress: addressStreetAddress
    address_streetAddress2: addressStreetAddress2
    address_zipCode: addressZipCode

    socialUrls_facebook: socialUrlsFacebook
    socialUrls_instagram: socialUrlsInstagram
    socialUrls_linkedin: socialUrlsLinkedin
    socialUrls_pinterest: socialUrlsPinterest
    socialUrls_twitter: socialUrlsTwitter
    socialUrls_youtube: socialUrlsYoutube

    operatingHours_monday_openTime: mondayOpenTime
    operatingHours_monday_closeTime: mondayCloseTime
    operatingHours_tuesday_openTime: tuesdayOpenTime
    operatingHours_tuesday_closeTime: tuesdayCloseTime
    operatingHours_wednesday_openTime: wednesdayOpenTime
    operatingHours_wednesday_closeTime: wednesdayCloseTime
    operatingHours_thursday_openTime: thursdayOpenTime
    operatingHours_thursday_closeTime: thursdayCloseTime
    operatingHours_friday_openTime: fridayOpenTime
    operatingHours_friday_closeTime: fridayCloseTime
    operatingHours_saturday_openTime: saturdayOpenTime
    operatingHours_saturday_closeTime: saturdayCloseTime
    operatingHours_sunday_openTime: sundayOpenTime
    operatingHours_sunday_closeTime: sundayCloseTime

    media {
      publicId
      type
    }
  }
`

export const placeFragmentWithEvents = gql`
  fragment placeFragmentWithEvents on Place {
    ...placeFragment

    events {
      nodes {
        id
        title
        startAt
        publishState

        image_publicId: imagePublicId
      }
    }
  }
  ${placeFragment}
`

export const placesMapFragment = gql`
  fragment placesMapFragment on Place {
    id
    slug
    name
    category
    geometry: geo
    mainImage_publicId: mainImagePublicId
  }
`

export const storyFragment = gql`
  fragment storyFragment on Story {
    id
    slug
    title
    publishedAt
    publishState
    category
    excerpt
    isFeatured

    image_publicId: imagePublicId

    # TODO: many-to-many
    communities {
      id
      slug
      displayName
      storyCategories
    }

    submitter {
      id
      profilePic_publicId: profilePicPublicId
      firstName
      lastName
      signature
    }
  }
`
